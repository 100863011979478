<template>
  <v-app>
    <div
      class="back"
      style="position: fixed; height: 100vh; width: 100vw;"
    />
    <v-container
      id="fundo"
      class="p-0 fill-height"
      fluid
    >
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <v-col
          cols="11"
          sm="8"
          md="5"
          lg="4"
          xl="3"
          style="padding: 0px"
        >
          <v-container :style="$vuetify.breakpoint.xs ? 'padding: 4px' : ''">
            <v-card
              color="rgba(255, 255, 255,0.88)"
              style="padding: 12px;"
              elevation="16"
            >
              <v-container class="pb-4">
                <v-row
                  class="layout-logos-login fill-height px-4 height"
                  align="center"
                  justify="center"
                >
                  <v-img
                    class="sombra_img"
                    src="@/assets/logo_branca.png"
                    height="200px"
                    contain
                  />
                </v-row>
              </v-container>
              <div style="width: 100%; text-align: center">
                <span
                  style="font-size: 16px; text-align: center; font-weight: 500; color: black"
                >Bem Vindo ao Portal Administrativo</span>
              </div>
              <div v-if="!loader">
                <v-form
                  id="form_login"
                  ref="form_login"
                  v-model="valid"
                  @submit.prevent="postLogin"
                >
                  <v-container class="p-4">
                    <v-text-field
                      v-model="userEntrada"
                      :rules="loginRules"
                      filled
                      rounded
                      dense
                      label="Email ou CPF/CNPJ"
                      name="userEntrada"
                      prepend-inner-icon="mdi-account"
                      validate-on-blur
                    />
                    <v-text-field
                      v-model="password"
                      filled
                      rounded
                      dense
                      prepend-inner-icon="mdi-key"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required]"
                      :type="show2 ? 'text' : 'password'"
                      name="password"
                      label="Senha"
                      @click:append="show2 = !show2"
                    />

                    <v-row
                      justify="center"
                      class="pt-2"
                    >
                      <v-btn
                        color="success"
                        type="submit"
                        rounded
                        dark
                      >
                        <span style="font-weight: 600">Acessar o Sistema</span>
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-form>
                <div
                  class="mb-3 justify-center"
                  style="text-align: center"
                >
                  <v-btn
                    text
                    small
                    rounded
                    @click="passwordForgot"
                  >
                    <span
                      style="font-size: 12px; color: black"
                    >Esqueci minha senha</span>
                  </v-btn>
                </div>
                <div
                  style="text-align: right;"
                >
                  <span style="font-size: 12px; color: grey;"> {{ version }}</span>
                </div>
              </div>
              <v-container v-else>
                <v-row
                  style="height: 229px"
                  justify="center"
                  align="center"
                >
                  <v-progress-circular
                    :size="100"
                    :width="8"
                    color="primary"
                    :indeterminate="true"
                  />
                </v-row>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackbar"
        top
        right
        :timeout="4000"
        color="error"
        transition="slide-x-reverse-transition"
      >
        <v-row
          justify="space-between"
        >
          <div class="d-flex align-center">
            <v-icon class="mx-2">
              mdi-alert-circle-outline
            </v-icon>
            <span style="font-size: 14px; font-weight: 500">{{ message }}</span>
          </div>
          <v-btn
            small
            icon
            dark
            text
            @click="snackbar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-snackbar>
    </v-container>
  </v-app>
</template>
<script>
  import { login } from '@/services/AuthService'
  import { validarCNPJ, validarCPF } from '../../utils/validacoes'
  import email from 'vuelidate/lib/validators/email'
  import rules from '../../utils/formRules'
  import packageJson from '../../../package.json'

  export default {
    data () {
      return {
        userEntrada: null, //
        password: null,
        email: null,
        formEnv: null,
        cpf: null,
        cnpj: null,
        user: null,
        snackbar: false,
        message: '',
        loader: false,
        valid: null,
        show1: false,
        show2: false,
        rules,
        loginRules: [
          (v) => {
            return (
              validarCPF(this.userEntrada) || validarCNPJ(this.userEntrada) || email(this.userEntrada)
            ) || 'Favor inserir um email, CPF ou CNPJ válido'
          },
          rules.required,
        ],
        version: this.getVersion(),
      }
    },
    methods: {
      postLogin () {
        if (this.$refs.form_login.validate()) {
          this.loader = true
          this.api.login(this.userEntrada, this.password).then(response => {
            login(response.user, response.access_token)
            return this.api.listEntidade('permission', { params: { list: true } })
          }).then(response => {
            this.$store.commit('SET_PERMISSIONS', response)
          }).catch(_err => {
            this.message = _err.message
            this.snackbar = true
            this.loader = false
          })
        }
      },
      passwordForgot () {
        this.Swal.fire({
          title: 'Digite o seu email',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off',
          },
          inputValue: this.email,
          showCancelButton: true,
          confirmButtonText: 'Enviar',
          showLoaderOnConfirm: true,
          preConfirm: email => {
            this.email = email
            return this.api.forgotPassword(email).then(
              data =>
                this.Swal.fire(
                  'E-mail enviado',
                  'E-mail com link para alteração de senha enviado!',
                  'success',
                ),
              error => {
                this.Swal.fire({
                  icon: 'error',
                  title: 'Insira um e-mail válido!',
                  text: error.message,
                })
              },
            )
          },
        })
      },
      getVersion () {
        const versionApi = localStorage.getItem('version_api')
        return 'versão: ' + (
          versionApi
            ? (versionApi + (process.env.NODE_ENV !== 'production' ? '(dev)' : '') + (packageJson.version !== versionApi
              ? '*' : ''))
            : packageJson.version + (process.env.NODE_ENV !== 'production' ? '(dev)' : '') + '*'
        )
      },
    },
  }
</script>
<style lang="css" scoped>

.v-card {
  border-radius: 20px;
}

.back {
  background-image: url('../../assets/loginimage.png');
  background-size: cover;
}

.layout-logos-login {
    align-items: center!important;
}

</style>
